import "../styles/dashboard.scss"
import React, { useState, useEffect } from "react"
// import { useAuth, useFirebaseArsenal } from "../lib/firebase/firebaseCentral"
import { loadStripe } from "@stripe/stripe-js"
import { Link } from "gatsby"
import toast from "react-hot-toast"
// import AuthGate from "../components/Auth/AuthGate"
import Header from "../components/Header"
import CSSLoader from "../components/Loaders/CSSLoader"
import GoTrue from "gotrue-js"
import firebase from "gatsby-plugin-firebase"
import { Helmet } from "react-helmet"
// import favicon from "../images/favicon.ico"
import favicon from "../images/favicon.png"
// import Footer from "../components/Footer"

export default function Dashboard() {
  // const { user, profile } = useAuth()
  // const { firebase } = useFirebaseArsenal()
  const [user, setUser] = useState(null)
  const [identity, setIdentity] = useState(null)

  const [stripeObj, setStripeObj] = useState({})
  const [menuType, setMenuType] = useState("subs")
  const [userProfile, setUserProfile] = useState(null)
  const [subscriptions, setSubscriptions] = useState([])
  const [orders, setOrders] = useState([])
  const [invoices, setInvoices] = useState([])
  const [activeMenu, setActiveMenu] = useState("subs")
  const [loading, setLoading] = useState(false)
  // useEffect(() => {
  //   document.documentElement.style.setProperty(
  //     "--scrollbar-width",
  //     window.innerWidth - document.documentElement.clientWidth + "px"
  //   )
  // }, [])
  console.log("subscriptions", subscriptions)

  useEffect(() => {
    const auth = new GoTrue({
      APIUrl: "https://www.berserkers.dev/.netlify/identity",
      audience: "",
      // setCookie(optional): set to be false by default. If you wish to implement the remember me functionality, set the value to be true.
      setCookie: false,
    })
    // console.log("auth", auth);
    setIdentity(auth)
  }, [])

  useEffect(() => {
    if (identity?.currentUser()?.email) {
      if (
        identity &&
        identity.currentUser &&
        identity.currentUser() &&
        identity.currentUser().email
      ) {
        const e = identity.currentUser().email
        // console.log("e", e);
        getUser(e)
      }

      async function getUser(e) {
        // console.log("e", e);
        const userRef = firebase
          .firestore()
          .collection("users")
          // .doc(identity.currentUser().email);
          .doc(e)
        const userDoc = await userRef.get()
        const userData = await userDoc.data()
        setUser(userData)
      }
    }
  }, [identity])

  const productNames = {
    prod_K7mqj9VvPaC1oo: "In-house SEO PRO",
    prod_K7moBw2GJFpE43: "In-house SEO BASIC",
    prod_K4TgHmz5S4bhQA: "berserkseo",
  }

  const getSubscriptions = async email => {
    // console.log("getSubscriptions | email", email);
    setLoading(true)
    // console.log("getSubscriptions | listSubscriptions | email", email)
    if (firebase) {
      const listSubscriptionsCallable = await firebase
        .functions()
        .httpsCallable("listSubscriptions")
      // console.log("getSubscriptions | listSubscriptions | email", email)
      const subs = await listSubscriptionsCallable({
        userId: email,
      })
      // console.log("subs", subs)
      setSubscriptions(subs.data.data)
    }
    setLoading(false)
  }
  const getInvoices = async email => {
    setLoading(true)
    if (firebase) {
      const listInvoicesCallable = await firebase
        .functions()
        .httpsCallable("listInvoices")
      const invoices = await listInvoicesCallable({
        userId: email,
      })
      // console.log("invoices", invoices)
      setInvoices(invoices.data.data)
    }
    setLoading(false)
  }
  const getOrders = async email => {
    setLoading(true)
    if (firebase) {
      const listOrdersCallable = await firebase
        .functions()
        .httpsCallable("listOrders")
      const orders = await listOrdersCallable({
        userId: email,
      })
      // console.log("orders", orders)
      setLoading(true)
      setOrders(orders.data.data)
    }
  }

  // Cancel subscription
  const cancelSubscription = async id => {
    setLoading(true)
    const cancelSubscriptionCallable = await firebase
      .functions()
      .httpsCallable("cancelSubscription")
    await cancelSubscriptionCallable({
      userId: user.email,
      subscriptionId: id,
    })

    toast.success("Your previous subscription has been canceled!")
    await getSubscriptions() // rehydrate subscriptions
    setLoading(false)
  }

  // ------ Get User Data ------
  useEffect(() => {
    console.log("firebase | user", [firebase, user])
    if (firebase && user?.email) {
      const unsubscribe = firebase
        .firestore()
        .collection("users")
        .doc(user.email)
        .onSnapshot(doc => {
          if (doc.exists) {
            console.log("user.email", user.email)
            setUserProfile(doc.data())
            getSubscriptions(user.email)
            // getOrders(user.email)
            getInvoices(user.email)
          }
        })

      //   const snapshotFn = doc => {
      //   }

      return () => {
        if (unsubscribe) {
          unsubscribe()
        }
      }
    }
  }, [firebase, user])

  // ----- Initialize Stripe ------
  useEffect(() => {
    let stripePromise
    const getStripe = () => {
      if (!stripePromise) {
        stripePromise = loadStripe(
          "pk_test_51JPrYxJ1tYytnqOaHXCvc4aK8UD2tBQ5mLCkQnXXRrkNW25MPpANcH8gtl3fM2R3YrQXk6TNzQzdEipkYRzdGsxJ00MnD6g6Df"
        )
      }
      return stripePromise
    }

    async function handleStripe() {
      const stripe = await getStripe().catch(err => console.warn("err", err))
      if (stripe) {
        // console.log("stripe", stripe)
        setStripeObj(stripe)
      }
    }

    handleStripe()
  }, [])

  // console.log("stripeObj", stripeObj)

  // console.log("user", user)
  return (
    <>
      {/* <AuthGate> */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dashboard</title>
        <link rel="icon" href={favicon} />
      </Helmet>
      <Header />
      <div className="dashboard">
        <div className="dashboard__heading-container">
          <div className="dashboard__heading">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248.98 34.56">
              <path
                className="cls-1"
                d="M469.9 205.78v34.08h-8.31v-2.35h-.14c-.19.43-2.54 2.83-6.91 2.83-5.57 0-11.81-4.23-11.81-13s6.24-13 11.81-13c4.37 0 6.72 2.4 6.91 2.83h.14v-11.39zm-8.11 21.6a5.45 5.45 0 10-5.48 5.61 5.31 5.31 0 005.48-5.61z"
                transform="translate(-220.92 -205.78)"
                id="dashboard"
              />
              <path
                className="cls-1"
                d="M441.63 214.66v7.92a7.39 7.39 0 00-1.83-.19c-5 0-7.68 3.26-7.68 7.77v9.7h-8.3v-25h8.3v6.81c.82-4.13 3.22-7.29 7.63-7.29a6.47 6.47 0 011.88.28z"
                transform="translate(-220.92 -205.78)"
                id="dashboar"
              />
              <path
                className="cls-1"
                d="M392.43 227.38c0-8.74 6.24-13 11.8-13 4.37 0 6.73 2.4 6.92 2.83h.14v-2.31h8.31v25h-8.31v-2.35h-.14c-.19.43-2.55 2.83-6.92 2.83-5.56-.04-11.8-4.27-11.8-13zm19.05 0A5.45 5.45 0 10406 233a5.3 5.3 0 005.48-5.62z"
                transform="translate(-220.92 -205.78)"
                id="dashboa"
              />
              <path
                className="cls-1"
                d="M364 227.38c0-8.5 6.77-13 13.35-13s13.29 4.46 13.29 13-6.76 13-13.29 13-13.35-4.51-13.35-13zm18.48 0a5.17 5.17 0 10-5.13 5.52 5.18 5.18 0 005.09-5.52z"
                transform="translate(-220.92 -205.78)"
                id="dashbo"
              />
              <path
                className="cls-1"
                d="M362.14 227.38c0 8.73-6.24 13-11.86 13-4.32 0-6.67-2.4-6.86-2.83h-.15v2.35H335v-34.12h8.3v11.47h.15c.19-.43 2.54-2.83 6.86-2.83 5.59 0 11.83 4.22 11.83 12.96zm-8.16 0a5.45 5.45 0 10-5.42 5.61 5.34 5.34 0 005.44-5.61z"
                transform="translate(-220.92 -205.78)"
                id="dashb"
              />
              <path
                className="cls-1"
                d="M330.75 225.17v14.69h-8.31v-13.25c0-2.59-1.24-4.85-4.08-4.85s-4 2.26-4 4.85v13.25h-8.31v-34.08h8.31v12.05a8.31 8.31 0 017.1-3.41c5.06 0 9.29 3.12 9.29 10.75z"
                transform="translate(-220.92 -205.78)"
                id="dash"
              />
              <path
                className="cls-1"
                d="M283.13 233.14l6.53-1.78c.34.87.91 2.55 3.12 2.55 1.15 0 2.11-.53 2.11-1.59 0-.53-.29-1.2-1.58-1.58l-3.65-1.35c-4.13-1.44-5.91-4.17-5.91-7.39 0-4.8 4.37-7.58 9.41-7.58s8.45 2.78 9.27 7L296 223c-.53-1.92-1.73-2.21-2.45-2.21a1.52 1.52 0 00-1.73 1.4c0 .57.34 1.24 1.78 1.63L297 225c2.4.76 6.09 2.64 6.09 7.29 0 5-4.32 8-10.13 8-5.17.05-9.06-2.29-9.83-7.15z"
                transform="translate(-220.92 -205.78)"
                id="das"
              />
              <path
                className="cls-1"
                d="M253.18 227.38c0-8.74 6.24-13 11.81-13 4.37 0 6.72 2.4 6.91 2.83h.1v-2.31h8.31v25H272v-2.35h-.14c-.19.43-2.54 2.83-6.91 2.83-5.53-.04-11.77-4.27-11.77-13zm19.06 0a5.45 5.45 0 10-5.48 5.61 5.31 5.31 0 005.48-5.61z"
                transform="translate(-220.92 -205.78)"
                id="da"
              />
              <path
                className="cls-1"
                d="M220.92 205.78h14c8.93 0 16.28 7.1 16.28 17s-7.35 17-16.28 17h-14zm13.68 26.11c5.19 0 7.73-3.79 7.73-9.07s-2.54-9.08-7.73-9.08h-5.23v18.15z"
                transform="translate(-220.92 -205.78)"
                id="d"
              />
            </svg>
          </div>
        </div>

        <div className="dashboard__container">
          {user?.name && user?.email && (
            <>
              <div className="dashboard__p">Name: {user.name}</div>
              <div className="dashboard__p">Email: {user.email}</div>
            </>
          )}

          <div className="dashboard__menu">
            <div
              onClick={() => {
                setActiveMenu("subs")
                setMenuType("subs")
              }}
              className={`${
                activeMenu === "subs"
                  ? "dashboard__active-menu"
                  : "dashboard__menu-p"
              } `}
            >
              Active Subscriptions
            </div>
            <div
              onClick={() => {
                setActiveMenu("orders")
                setMenuType("orders")
              }}
              className={`${
                activeMenu === "orders"
                  ? "dashboard__active-menu dashboard__menu-p"
                  : "dashboard__menu-p"
              } `}
            >
              Order History
            </div>
          </div>

          {menuType === "subs" ? (
            <>
              <div className="dashboard__h">Subscriptions</div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {loading && <CSSLoader />}
              </div>
              {!loading &&
                subscriptions?.map(s => (
                  <div className="dashboard__block">
                    {s?.plan?.product && (
                      <>
                        <div className="dashboard__product-title">
                          {productNames?.[s.plan.product]}
                        </div>
                        {user?.email && (
                          <div
                            onClick={() => cancelSubscription(s.id)}
                            className="dashboard__product-cancel"
                          >
                            Cancel
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ))}
            </>
          ) : (
            <>
              <div className="dashboard__h-wrapper">
                <div className="dashboard__p">Date</div>
                <div className="dashboard__p">Download Invoice</div>
                <div className="dashboard__p">Charge</div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* {!invoices?.length && <CSSLoader />} */}
                {loading && <CSSLoader />}
              </div>
              {!loading &&
                invoices?.map(i => (
                  <div className="dashboard__block">
                    <div className="dashboard__p">{getDate(i.created)}</div>
                    <a href={`${i.invoice_pdf}`} className="dashboard__p">
                      Download
                    </a>
                    <div className="dashboard__p">{prettifyCost(i.total)}</div>
                  </div>
                ))}
            </>
          )}
        </div>
      </div>
      {/* <Footer /> */}
      {/* </AuthGate> */}
    </>
  )
}

function getDate(unix_timestamp) {
  // Months array
  const months_arr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]

  // Convert timestamp to milliseconds
  const date = new Date(unix_timestamp * 1000)

  // Year
  const year = date.getFullYear()

  // Month
  const month = months_arr[date.getMonth()]

  // Day
  const day = date.getDate()

  // Hours
  const hours = date.getHours()

  // Minutes
  const minutes = "0" + date.getMinutes()

  // Seconds
  const seconds = "0" + date.getSeconds()

  // Display date time in MM-dd-yyyy h:m:s format
  // const calendarDate =
  //   month +
  //   "-" +
  //   day +
  //   "-" +
  //   year +
  //   " " +
  //   hours +
  //   ":" +
  //   minutes.substr(-2) +
  //   ":" +
  //   seconds.substr(-2)
  const calendarDate = month + "-" + day + "-" + year

  // console.log("calendarDate", calendarDate)
  return calendarDate
}

const prettifyCost = cost => {
  const costArr = cost.toString().split("")
  const dollars = costArr.slice(0, costArr.length - 2).join("")
  const cents = costArr.slice(-2).join("")

  return "$" + dollars + "." + cents
}
